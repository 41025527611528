import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tarik's RM2K Site",
  "path": "Tarikrm2ksite.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Hi! Welcome to my RM2K Section of my site! RM2K is RPG Maker 2000. You could make RPGs! I have some. I'll make my games downloadable games of mine soon for you to play!`}</p>
    <p>{`3/16/02`}</p>
    <p>{`I'm adding an RM2K and a RM2K help chat! And in affiliates I'm adding the RM2K Site. There you could download RM2K!!!`}</p>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`Affiliates`}</a>{`
`}<a parentName="p" {...{
        "href": ""
      }}>{`Java Chat`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      